import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode as TContentNode, Person } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, ContentTitle, EntityFilters, EntityGrid, useView, useLang, useOrganisations, usePeople } from '@shapeable/ui';
import { Catalyst, Innovation, Organisation } from '@shapeable/copper-connect-types';
import { EntityEmbedReveal } from './entity-embed-reveal';
import { TabSpec, Tabs } from '../elements/tabs';
import { HorizonCardGrid } from './horizon-card-grid';
import { compact, sortBy, flatMap } from 'lodash';
import { MarketMapGrid } from './market-map-grid';
import { CommunityBioCellGrid } from './community-bio-cell-grid';
import { ExplorerCitationCard } from './explorer-citation-card';
import { ExplorerView } from '../elements/explorer-view';
import { useCommunityFilters } from '../../hooks/use-community-filters';
import { useMarketMapFilters } from '../../hooks/use-market-map-filters';
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { classNames } from '@shapeable/utils';
const cls = classNames('innovation-main-layout');

// -------- Types -------->

export type InnovationView = 'details' | 'community' | 'stakeholder-map' | 'library';
export type InnovationMainLayoutProps = Classable & HasChildren & { 
  entity?: Innovation;
};

export const InnovationMainLayoutDefaultProps: Omit<InnovationMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('heading')};
    font-weight: 600;
    color: ${theme.COLOR('heading')};
    font-size: 1em;
    margin-bottom: 0.5em;
  `,
});

const TechnologyTitleStyles = breakpoints({
  base: css`
    border-bottom: 1px solid #BFC0BF};
    
  `
});

const ExampleStyles = breakpoints({
  base: css`
    
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
  `,
});

const CatalystStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
    width: 100%;
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const FiltersStyles = breakpoints({
  base: css`
  `,
});




const HorizonTypeStyles = breakpoints({
  base: css`
    
  `,
});

const StakeholderCellStyles = breakpoints({
  base: css`
    
  `,
});

const MarketMapStyles = breakpoints({
  base: css`
    
  `,
});

const CitationsStyles = breakpoints({
  base: css`
    
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
      ContentTitle: styled(ContentTitle)`${ContentTitleStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
      Example: styled(ContentNode)`${ExampleStyles}`,
      Horizontype: styled(HorizonCardGrid)`${HorizonTypeStyles}`,
      Catalyst: styled(EntityEmbedReveal)`${CatalystStyles}`,
      Tabs: styled(Tabs)`${TabsStyles}`,

      Filters: styled(EntityFilters)`${FiltersStyles}`,

      Stakeholders: styled(CommunityBioCellGrid)`${StakeholderCellStyles}`,

      TechnologyTitle: styled(ContentTitle)`${TechnologyTitleStyles}`,
      MarketMap: styled(MarketMapGrid)`${MarketMapStyles}`,

      Citations: styled(EntityGrid)`${CitationsStyles}`,
    

};

export const InnovationMainLayout: Shapeable.FC<InnovationMainLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const { description, example, catalysts = [], horizons = [], people = [], 
    organisations = [], citations = [] } = entity;

  const view = `innovations-${entity.slug}`;
  const allOrganisations = useOrganisations();
  const allPeople = usePeople();

  const communityFilters = useCommunityFilters('innovation-community');
  const marketMapFilters = useMarketMapFilters('innovation-community');
  const t = useLang();

  const horizonItro = {
    text: "The horizons note the current state of this innovation, whether it is available now, in a state of evolution or emerging as a future solution."
  }

  const stakeholders = [...sortBy(organisations, 'name'), ...sortBy(people, 'name')];

  const hasTechnologies = entity.technologies.filter(technology => technology?.organisations?.length > 0);
  
  const technologyOrganisations = flatMap(entity.technologies, technology => technology?.organisations);

  const hasHorizons = !!horizons.length;

  const hasCommunity = !!stakeholders.length;
  const hasMarketMap = !!hasTechnologies.length; 
  const hasLibrary = !!citations.length; // TODO hook this up to library checks

  const hasCatalysts = !!catalysts.length;

  const tabs: TabSpec<InnovationView>[] = compact([
    {
      id: 'details',
      label: 'Details',
    },
    hasCommunity && {
      id: 'community',
      label: 'Community',
    },
    hasMarketMap && {
      id: 'stakeholder-map',
      label: 'Stakeholder Map',
    },
    hasLibrary && {
      id: 'library',
      label: 'Library',
    },
  ]);

  const { activeView, setActiveView } = useView<InnovationView>('innovation', 'details');
  const { resultsFor, filters } = useView(view);
  const { types = [], technologies = [] } = filters;


  const filteredMarketMap = hasTechnologies.filter(technology => 
    linkedMatchesFilter(compact([technology]), technologies) 
    // linkedMatchesFilter(compact([technology?.organisations?.type]), types) 
  );

  const filteredPeople = people.filter(person => (
    linkedMatchesFilter(person.technologies, technologies)
  ));

  const filteredOrganisations = organisations.filter(organisation => (
    linkedMatchesFilter(compact([organisation?.type]), types) &&
    linkedMatchesFilter(organisation.technologies, technologies)
  ));

  const filteredStakeholders = [
    ...(resultsFor({ type: 'Organisation', data: filteredOrganisations, by: 'name' })), 
    ...(resultsFor({ type: 'Person', data: filteredPeople, by: 'name' })),
  ];

  return (
    <My.Container className={cls.name(className)}>
      {
        tabs.length > 1 &&
        <My.Tabs onTabClick={setActiveView} activeTab={activeView} tabs={tabs} variant='section' />
      }
      <ExplorerView>
      {
        activeView === 'details' && <>
          <My.Section>
            <My.ContentTitle>{t('Objectives')}</My.ContentTitle>
            <My.Description entity={description as TContentNode} />
          </My.Section>
          <My.Section>
            <My.ContentTitle>{t('Example')}</My.ContentTitle>
            <My.Example entity={example as TContentNode} />
          </My.Section>
        
          <My.Section>
            <My.ContentTitle>{t('Horizons')}</My.ContentTitle>
            {
              hasHorizons && 
              <>
                <My.Description entity={horizonItro}/>
                <My.Horizontype items={horizons} />
              </>
            }
          </My.Section>

          <My.Section>
          <My.ContentTitle>{t('Catalysts')}</My.ContentTitle>
            {
              hasCatalysts &&
              catalysts.map(catalyst => <My.Catalyst key={catalyst.id} entity={catalyst} />)
            }
          </My.Section>
          </>
      }
      {
        activeView === 'community' && 
        <>
          <My.Section>
            {/* <My.Description entity={description as TContentNode} /> */}
          </My.Section>

          <My.Section>
            <My.Filters 
              view={view}
              selects={communityFilters.selects}
              typeToggles={communityFilters.typeToggles}
            />
          </My.Section>

          <My.Section>
          {
            hasCommunity && <My.Stakeholders items={filteredStakeholders} />
          }
          </My.Section>
          </>
      }
      {
        activeView === 'stakeholder-map' && 
        <>
        <My.Section>
          <My.Filters 
            results={technologyOrganisations}
            view={view}
            selects={marketMapFilters.selects}
          />
        </My.Section>

        <My.Section>
        {
          hasMarketMap &&
          filteredMarketMap.map(({ name, organisations }) => (
            <>
            <My.TechnologyTitle>
              <My.ContentTitle>{name}</My.ContentTitle>
            </My.TechnologyTitle>
            <My.MarketMap items={organisations} />
            </>
          ))
        }
        </My.Section>
          </>
      }
      {
        activeView === 'library' && 
          <My.Section>
            {
              hasLibrary && <My.Citations items={citations} card={ExplorerCitationCard}/>
            }
          </My.Section>
      }
      </ExplorerView>
    </My.Container>
  )
};

InnovationMainLayout.defaultProps = InnovationMainLayoutDefaultProps;
InnovationMainLayout.cls = cls;