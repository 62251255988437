import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, ContentEntity, HasChildren, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { Horizon, HorizonType } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, useEntity } from '@shapeable/ui';
import { HorizonOne } from '../icons/horizon-one';
import { classNames } from '@shapeable/utils';
const cls = classNames('horizon-card');

// -------- Types -------->

export type HorizonCardProps = Classable & HasChildren & { 
  entity?: HorizonType;
  hasTick?: boolean;
  description?: TContentNode;
};

export const HorizonCardDefaultProps: Omit<HorizonCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type BannerProps = {
  border: number;
  hasTick: boolean
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    margin-top: 12px;
  `,
});

const NameStyles = breakpoints({
  base: css`
    display: flex;
    padding: 4px 0px;
    justify-content: center;
    gap: 4px;
    align-self: stretch;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    overflow: hidden;
    color: var(--shapeable-text-mid, #969696);
    text-align: center;
    text-overflow: ellipsis;
    font-family: IBM Plex Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.15px;
  `,
});


const BannerStyles = breakpoints({
  base: css`
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-top: 1px solid var(--shapeable-line, ${theme.COLOR('line-mid')});
    border-right: 1px solid var(--shapeable-line, ${theme.COLOR('line-mid')});
    border-bottom: 1px solid var(--shapeable-line, ${theme.COLOR('line-mid')});
    background: var(--horzon-radial-gradient, linear-gradient(270deg, rgba(204, 124, 75, 0.00) 0%, rgba(204, 124, 75, 0.25) 50%, rgba(204, 124, 75, 0.00) 100%));

    ${({ border }: BannerProps) => (border === 3 && css`
      border-right: 1px solid transparent;
  `)}
    ${({ hasTick }: BannerProps) => (!hasTick && css`
      background: var(--shapeable-background, #FFFFFF);
    `)}
  `,
});

const IconStyles = breakpoints({
  base: css`
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    ${theme.FILL('#F47B20')}
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    padding: 12px 16px 12px 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});






// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Name: styled.div`${NameStyles}`,
      Title: styled.span`${TitleStyles}`,
    Banner: styled.div<BannerProps>`${BannerStyles}`,
      Icon: styled.div`${IconStyles}`,
    Body: styled.div`${BodyStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
};

export const HorizonCard: Shapeable.FC<HorizonCardProps> = (props) => {
  const { className, children, entity, hasTick, description } = props;
  const { value, subtitle } = entity;

  return (
   <My.Container className={cls.name(className)}>
    <My.Name>
      <My.Title>HORIZON {value} ({subtitle}) </My.Title>
    </My.Name>
    <My.Banner hasTick={hasTick} border={value}>
      <My.Icon>
        {
          hasTick && <HorizonOne height='36' width='37' viewBox='0 0 17 18' />
        }
      </My.Icon>
    </My.Banner>
    <My.Body>
       {
        hasTick && <My.Description entity={description as TContentNode} />
       }
    </My.Body>
   </My.Container>
  )
};

HorizonCard.defaultProps = HorizonCardDefaultProps;
HorizonCard.cls = cls;