import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity, EntityValuePillList } from '@shapeable/ui';
import { Innovation } from '@shapeable/copper-connect-types';
import { SdgCardGrid } from './sdg-card-grid';
import { classNames } from '@shapeable/utils';
const cls = classNames('innovation-Aside-layout');

// -------- Types -------->

export type InnovationAsideLayoutProps = Classable & HasChildren & { 
  entity: Innovation;
};

export const InnovationAsideLayoutDefaultProps: Omit<InnovationAsideLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(5)} ${theme.UNIT(5)};
  `,
});

const ValueListStyles = breakpoints({
  base: css`
    
  `,
});

const ListsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
  `,
});

const SdgCardStyles = breakpoints({
  base: css`

  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Lists: styled.div`${ListsStyles}`,
      List: styled(EntityValuePillList)`${ValueListStyles}`,
      Sdgs: styled(SdgCardGrid)`${SdgCardStyles}`
};

export const InnovationAsideLayout: Shapeable.FC<InnovationAsideLayoutProps> = (props) => {
  const { className, children, entity } = props;
  
  const { 
    technologies = [], catalysts = [], impactResultTypes = [],
    barriers = [], commodityGroups = [], sdgs = []
    
  } = entity;

  return (
    <My.Container className={cls.name(className)}>
      <My.Lists>
        <My.List variant="tiny" items={technologies} />
        <My.List variant="tiny" items={catalysts} />
        <My.List variant="tiny" items={impactResultTypes} />
        <My.List variant="tiny" items={barriers} />
        <My.List variant="small" showLabel items={commodityGroups} />
        <My.Sdgs items={sdgs} />
      </My.Lists>

    </My.Container>
  )
};

InnovationAsideLayout.defaultProps = InnovationAsideLayoutDefaultProps;
InnovationAsideLayout.cls = cls;