import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Sdg } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, SdgLogo } from '@shapeable/ui';
import { get } from 'lodash';
import { classNames } from '@shapeable/utils';
const cls = classNames('sdg-card-grid');

// -------- Types -------->

export type SdgCardGridProps = Classable & HasChildren & { 
  items: Sdg[];
};

export const SdgCardGridDefaultProps: SdgCardGridProps = {
  items: []
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
  `,
});

const LabelStyles = breakpoints({
  base: css`
    
  `,
});



const ItemStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Label: styled(CellLabel)`${LabelStyles}`,
    Sdgs: styled.div`${ItemStyles}`,
    Sdg: styled(SdgLogo)`${ItemStyles}`,
};

export const SdgCardGrid: Shapeable.FC<SdgCardGridProps> = (props) => {
  const { className, children, items } = props;
  const schema = get(items, '[0]._schema', {} );
  
  return (
    <My.Container className={cls.name(className)}>
      {
        schema.pluralLabel && <My.Label>{schema.pluralLabel}:</My.Label>
      }
    <My.Sdgs>
      {
        items.map(Sdg => <My.Sdg key={Sdg.id} entity={Sdg} /> )
      }
    </My.Sdgs>
    {children}
    </My.Container>
  )
};

SdgCardGrid.defaultProps = SdgCardGridDefaultProps;
SdgCardGrid.cls = cls;