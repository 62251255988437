import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, ContentNode as TContentNode } from '@shapeable/types';
import { Catalyst } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityValue, useEntity, ContentNode, useLink } from '@shapeable/ui';
import { SelectIndicator } from 'components/elements/select-indicator';
import { classNames } from '@shapeable/utils';
const cls = classNames('entity-embed-reveal');

// -------- Types -------->

export type EntityEmbedRevealProps = Classable & HasChildren & { 
  entity?: Catalyst;
  onClick?: (event: any) => void;
};

export const EntityEmbedRevealDefaultProps: Omit<EntityEmbedRevealProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
    margin-top: 16px;

    .shp--entity-value__name {
      &:hover {
        color: ${theme.COLOR('link-hover')};
      }
    }
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  `,
});

const TypeStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('dark')}
  `,
});

const ContentStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Type: styled(EntityValue)`${TypeStyles}`,
      Content: styled(ContentNode)`${ContentStyles}`,
      
};

export const EntityEmbedReveal: Shapeable.FC<EntityEmbedRevealProps> = (props) => {
  const { className, children, onClick } = props;
  const entity = useEntity(props.entity);

  const [ isExpanded, setIsExpanded ] = useState<boolean>(false);

  const { description } = entity;

  const href = entity.path || '';
  
  const { Link } = useLink();
  
  return (
   <My.Container onClick={(event: any) => { event.stopPropagation(); setIsExpanded((isExpanded) => !isExpanded)}} className={cls.name(className)}>
    <My.Body>
      <Link style={{ textDecoration: 'none' }} href={href}>
        <My.Type entity={entity} alwaysShowIcon={true}/>
      </Link>
      {/* <SelectIndicator direction={isExpanded ? 'up' : 'down'}/> */}
    </My.Body>
    {/* {
      isExpanded && <My.Content entity={description as TContentNode} /> 
    } */}
    <My.Content entity={description as TContentNode} /> 
   {children}
   </My.Container>
  )
};

EntityEmbedReveal.defaultProps = EntityEmbedRevealDefaultProps;
EntityEmbedReveal.cls = cls;